import { area, length } from "@turf/turf";

export default {
  nbsSize: function (feature) {
    const type = this.objectTypes[feature.properties.objectType];
    let size = 0;

    if (type.unit === "pcs") return 1;
    else {
      try {
      switch (feature.geometry.type) {
        case "MultiPolygon":
        case "Polygon":
          size = area(feature);
          break;
        case "LineString":
          size = length(feature, { units: "meters" });
          break;
        default:
          size = 1;
          break;
      }
    } catch(e) {
      console.log(e)
      size = 1
    }
    }
    return Math.floor(size);
  },

  /**
   * Calculates the area or number of deployed nbs by type for the current year.
   * @return { tree: {size: 1000, unit: 'pcs'} }
   */
  deployedNbs: function (
    year = null,
    type = null,
    implementations = null,
    types = null
  ) {
    if (!year) year = this.year;
    const deployedNbs = {};
    implementations = (!implementations && this.solution.implementations) ?
      this.solution.implementations.features : []
    if (types) {
      this.objectTypes = types;
    }
    if (year) {
      implementations =
        implementations.filter((i) => {
          if (!i.properties || !i.properties.timeline) {
            return false;
          }
          return i.properties.timeline.toString() <= this.year.toString();
        }) || {};
    }
    if (implementations.features) {
      implementations = implementations.features;
    }
    for (let i = 0; i < implementations.length; i++) {
      const o = implementations[i];

      deployedNbs[o.properties.objectType] = deployedNbs[
        o.properties.objectType
      ] || {
        size: 0,
        unit: this.objectTypes[o.properties.objectType].unit,
        cost: 0,
        maintenance: 0,
      };
      const size = this.nbsSize(o);
      deployedNbs[o.properties.objectType].size += size;
      if (this.objectTypes[o.properties.objectType].cost) {
        deployedNbs[o.properties.objectType].cost +=
          size * this.objectTypes[o.properties.objectType].cost.base;
        if (this.objectTypes[o.properties.objectType].cost.maintenance) {
          deployedNbs[o.properties.objectType].maintenance +=
            size *
            parseFloat(
              this.objectTypes[o.properties.objectType].cost.maintenance.value
            );
        }
      }
    }

    if (type) {
      return deployedNbs[type] ? deployedNbs[type] : false;
    }
    return deployedNbs;
  },
};
