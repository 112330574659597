import { bbox, featureCollection, intersect, bboxPolygon } from "@turf/turf";
import { json } from "d3-fetch";

// Contact OSM Buildings API and get buildings data for a site.
export function loadBuildings(map, boundaries, buildings) {
  const zoom = 15;
  // lon = x, lat = y
  function lon2tile(lon, zoom) {
    return Math.floor(((lon + 180) / 360) * Math.pow(2, zoom));
  }
  function lat2tile(lat, zoom) {
    return Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
          Math.PI) /
        2) *
        Math.pow(2, zoom)
    );
  }

  const box = bbox(boundaries);

  const xmin = lon2tile(box[0], zoom);
  const ymax = lat2tile(box[1], zoom);
  const xmax = lon2tile(box[2], zoom);
  const ymin = lat2tile(box[3], zoom);

  for (let x = xmin; x <= xmax; x++) {
    for (let y = ymin; y <= ymax; y++) {
      json(
        "https://data.osmbuildings.org/0.2/anonymous/tile/" +
          zoom +
          "/" +
          x +
          "/" +
          y +
          ".json"
      ).then((json) => {
        buildings.features = buildings.features.concat(
          json.features
            .filter((f) => {
              return intersect(boundaries, f);
            })
            .map((f) => {
              f.properties.building = true;
              return f;
            })
        );
        setBuildings(map, buildings);
      });
    }
  }
}

// Set buildings as a layer for a mapboxgl map.
export function setBuildings(map, buildings) {
  if (!map) return;

  if (map.getSource("buildings")) {
    map.getSource("buildings").setData(buildings);
  } else {
    map.addSource("buildings", {
      type: "geojson",
      data: buildings,
      generateId: true,
    });
    map.addLayer({
      id: "buildings-line",
      type: "line",
      source: "buildings",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#000",
        "line-width": 1,
        "line-opacity": 0.5,
      },
    });
  }
}

export function obstaclesUnder(map, objects, layers = ["water", "road"]) {
  const obstacles = featureCollection([]);
  objects.features.forEach((f) => {
    const box = bboxPolygon(bbox(f));
    const o = map.queryRenderedFeatures([
      map.project(box.geometry.coordinates[0][0]),
      map.project(box.geometry.coordinates[0][1]),
      map.project(box.geometry.coordinates[0][2]),
      map.project(box.geometry.coordinates[0][3]),
    ]);

    obstacles.features = obstacles.features.concat(
      o.filter((f) => {
        return layers.indexOf(f.layer["source-layer"]) > -1;
      })
    );
  });

  return obstacles;
}
