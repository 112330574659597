<template>
  <div>
    <div class="admin-header-menu">
      <h1>Edit predefined areas</h1>
      <div class="buttons">
        <button
          class="edit white"
          v-if="nbsTypes && $refs.map && !$refs.map.modeSelected"
          @mousedown.prevent="startDrawing"
        >
          Add {{ nbsTypes[nbsType].name }}
        </button>
        <button
          v-if="$refs.map && $refs.map.modeSelected"
          @mousedown.prevent="stopDrawing"
        >
          Stop drawing
        </button>
        <button @mousedown.prevent="back">Done</button>
      </div>
    </div>
    <div class="admin-page">
      <div class="error">{{ error }}</div>

      <div id="admin-debug">
        <h3>NBS values</h3>
        <div></div>
        <div v-if="scenario">
          <div v-for="(data, type) in nbs" v-bind:key="type">
            <strong>{{ type }}</strong>
            {{ data.size }} {{ data.unit }}: {{ data.cost }} coins
          </div>
        </div>
      </div>

      <div id="map-wrapper">
        <map-widget
          ref="map"
          :id="mapId"
          :boundaries="boundaries"
          :defaultZoom="siteZoom"
          :editable="true"
          :width="1008"
          :height="800"
          :objects="implementation"
          :displayOnly="displayOnly"
          :objectTypes="mapObjectTypes"
          :rotate="rotate"
          :showDisplayOnlyIcons="false"
          @input="saveScenario"
        />
      </div>

      <div id="scenario-details">
        <!-- 			<select v-model="nbsType">
					<option :value="null">[select]</option>
					<template v-for="(type, index) in nbsTypes">
						<option :value="type._id" :checked="nbsType == type._id" v-bind:key="index">{{type.name}}</option>
					</template>
				</select>-->
      </div>
    </div>
  </div>
</template>

<script>
import MapWidget from "../../components/MapWidget";
import * as turf from "@turf/turf";
import { setBuildings, loadBuildings } from "../../buildings";
import calc from "../../nbs";
import { addBuildingObject, setDrawMode } from "../../mapObjectTypes";

export default {
  name: "ImplementationGame",
  components: {
    MapWidget,
  },
  data() {
    return {
      error: "",
      nbsTypes: {},
      scenario: this.$store.state.scenario,
      currentScenario: null,
      scenarioOptions: [],
      boundaries: null,
      mapId: "map-implementation-edit",
      mapObjectTypes: {},
      siteZoom: 13,
      indicators: [],
      predefined: { type: "FeatureCollection", features: [] },
      hiddenPredefined: { type: "FeatureCollection", features: [] },
      implementation: { type: "FeatureCollection", features: [] },
      displayOnly: { type: "FeatureCollection", features: [] },
      warning: null,
      buildings: { type: "FeatureCollection", features: [] },
      window: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      nbs: {},
      nbsType: this.$store.state.currentNbs._id || null,
    };
  },
  created() {},
  watch: {
    nbsType(value) {
      this.setImplementation(value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.map.map.on("load", () => {
        this.$store
          .dispatch("loadProjects", {
            _id: { $oid: this.$store.state.currentProject._id.toString() },
          })
          .then((workshops) => {
            const load = () => {
              this.boundaries = turf.lineToPolygon(
                turf.lineString(this.site.boundaries.geometry.coordinates[0]),
                { autoComplete: true }
              );
              if (this.site.buildings) {
                this.buildings = this.site.buildings;
                setBuildings(this.$refs.map.map, this.buildings);
              }

              this.siteZoom = this.site.zoomSmall || 13;

              this.predefined =
                this.site.predefined ||
                this.scenario.predefined ||
                turf.featureCollection([]);
              this.implementation = turf.featureCollection([]);
              this.displayOnly = this.site.trees || turf.featureCollection([]);
              this.$refs.map.map.on("load", () => {
                if (!this.site.buildings) {
                  loadBuildings(
                    this.$refs.map.map,
                    this.boundaries,
                    this.buildings
                  );
                }
              });
              this.setImplementation(this.nbsType);
            };
            this.nbsTypes = workshops[0].nbsTypes;
            this.loadMapObjectTypes();
            this.$refs.map.loadIcons();
            if (typeof this.scenario.site == "string") {
              this.$store
                .dispatch("loadSite", { _id: { $oid: this.scenario.site } })
                .then((site) => {
                  this.site = site;
                  load();
                });
            } else if (typeof this.scenario.site == "object") {
              this.site = this.scenario.site;
              load();
            } else {
              this.site = this.$store.state.currentProject.site;
              load();
            }
          });

        // this.$refs.map.objectTypeControls();
        this.$refs.map.map.on("draw.selectionchange", () => {
          const s = this.$refs.map.draw.getSelected();
          if (s) {
            this.nbs = calc.deployedNbs(null, null, s, this.mapObjectTypes);
          }
        });
      });
    });
  },
  computed: {
    rotate() {
      if (this.scenario) {
        return parseFloat(this.scenario.rotate) || 0;
      } else {
        return 0;
      }
    },
  },
  methods: {
    back() {
      this.$router.push({ path: "/Admin/Scenario" });
    },

    setImplementation(value) {
      const implementation = turf.featureCollection([]);
      const hiddenPredefined = turf.featureCollection([]);
      this.predefined.features.forEach((f) => {
        if (f.properties.objectType == value) {
          implementation.features.push(f);
        } else {
          hiddenPredefined.features.push(f);
        }
      });
      this.implementation = implementation;
      this.hiddenPredefined = hiddenPredefined;
    },
    startDrawing() {
      if (this.nbsType) {
        const type = this.nbsTypes[this.nbsType];
        if (type.behavior && type.behavior.buildings) {
          this.info =
            "Select buildings where you place the " + type.name.toLowerCase();
          addBuildingObject(this.$refs.map, type);
        } else {
          setDrawMode(this.$refs.map, type);
        }
      }
    },
    stopDrawing() {
      this.$refs.map.resetListener();
      this.$refs.map.modeSelected = false;
      this.info = "";
    },

    setScenario() {
      this.scenario = this.$store.state.scenarios[this.currentScenario];
    },
    // Load NBS Type definitions and load them into a place where the map can use it.
    loadMapObjectTypes() {
      const nbsTypes = this.nbsTypes;
      for (const k in nbsTypes) {
        if (
          nbsTypes.hasOwnProperty(k) &&
          nbsTypes[k].object &&
          nbsTypes[k].object.predefined
        ) {
          this.mapObjectTypes[k] = JSON.parse(JSON.stringify(nbsTypes[k]));
          delete this.mapObjectTypes[k].object.fixed;
          delete this.mapObjectTypes[k].object.predefined;
        }
      }
      this.$refs.map.addObjectModes();
      this.$refs.map.addDraw();
    },
    saveScenario(newObjects, objects) {
      this.error = "";
      const processedObjects = [];
      objects.features.forEach((f, i) => {
        if (this.nbsTypes[f.properties.objectType]) {
          if (
            this.nbsTypes[f.properties.objectType].object &&
            this.nbsTypes[f.properties.objectType].object.options
          ) {
            for (const p in this.nbsTypes[f.properties.objectType].object
              .options) {
              if (
                this.nbsTypes[
                  f.properties.objectType
                ].object.options.hasOwnProperty(p)
              ) {
                f.properties[p] =
                  this.nbsTypes[f.properties.objectType].object.options[p];
              }
            }
          }
          processedObjects[i] = f;
        }
      });
      this.predefined = turf.featureCollection(
        [].concat(this.hiddenPredefined.features, processedObjects)
      );
      this.site.predefined = this.predefined;
      this.implementation = objects;
      // this.$store.dispatch('saveScenario', this.scenario).catch(() => {
      // 	this.error = 'Undable to save challenge data'
      // })
      this.$store
        .dispatch("saveSite", this.site)
        .then((site) => {
          this.$store.state.scenario.site = site;
          this.$store.commit("setSite", site);
        })
        .catch((e) => {
          this.error = "Unable to save site data: " + e;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/admin.scss";

.error {
  position: fixed;
  top: 10px;
  left: 30px;
  background: red;
  color: white;
}

#scenario-details {
  background-color: white;
  position: absolute;
  top: 100px;
  right: 50px;
  padding: 15px;
  border-radius: 3px;
}
#scenario-details textarea.form-control {
  height: 250px;
}

#scenario-details .target textarea.form-control {
  height: 120px;
}

#admin-debug {
  background: white;
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 350px;
  padding: 20px;
}
</style>
